
import { computed } from 'vue'
import { useThemeColors } from '../compose/useThemeColors'

export default {
  setup() {
    const { themeColors, setThemeColor, themeColor } = useThemeColors()
    return { themeColor, themeColors, setThemeColor }
  }
}
