
import { defineComponent, reactive, ref, watch } from "vue"
import axios from 'axios'
import moment from 'moment'
import useAsyncState from '../compose/useAsyncState'
import { DateTime } from 'luxon'
import _ from 'lodash'
import BibleChapter from './BibleChapter.vue'

type Report = {
  id: number
  text: string
  verse: string
  book: string
}
type ReportResponse = {
  verses: Report[]
}

export default defineComponent({
  components: {
    BibleChapter
  },
  setup() {
    const search = ref('')
    const status = ref('in_review')
    const selectedReport = ref<Report>()

    const labels = {
      in_review: 'neu',
      ok: 'freigeschaltet'
    } 

    const request = useAsyncState(
      () => axios.get<ReportResponse>('/api/search/query', {params: {
        q: search.value
      }}).then(x => x.data.verses)
    )
    request.run()
    watch(search, request.run)
    watch(status, request.run)
    watch(status, () => selectedReport.value = undefined)
    // Reset edit object on select report change

    return {
      msg: String,
      reports: request.data,
      error: request.error,
      state: request.state,
      search,
      selectedReport,
      status,
      labels,
    }
  }
})
