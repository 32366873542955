
import Vue, { defineComponent } from 'vue'
import ThemeColor from './components/ThemeColor.vue'
import { useThemeColors } from './compose/useThemeColors'

export default defineComponent({
  components: {
    'theme-color': ThemeColor,
  },
  setup() {
    const { themeColor } = useThemeColors()
    return { themeColor }
  }
})
